(function ($, F) {
    /**
     * Checks if the row is filtered using the supplied filters.
     * @this FooTable.Row
     * @param {Array.<FooTable.Filter>} filters - The filters to apply.
     * @returns {boolean}
     */
    F.Row.prototype.filtered = function (filters) {
        var result = true,
            self = this;
        F.arr.each(filters, function (f) {
            if (result) { //check if its already flag to false;
                if (f.query._original.charAt(0) == ">") {

                    var search = f.query._original.substring(1);

                    if (search.indexOf('-') != -1) {
                        var from_date = moment(f.query._original.substring(1), 'YYYY-MM-DD');
                    } else {
                        var from_date = moment(f.query._original.substring(1), 'DD.MM.YYYY');
                    }

                    // check all columns that have a date
                    var to_check_dates = f.columns.filter(function (c) {
                        return c.type == 'date'
                    })

                    if (to_check_dates.length != 0) {
                        result = false;

                        for (var i = 0; i < to_check_dates.length; i++) {
                            var to_check_date = moment(self.value[to_check_dates[i].name], 'DD.MM.YYYY');

                            if (from_date.isValid()) {
                                result = to_check_date.isSameOrAfter(from_date);

                                if (result) {
                                    return (result)
                                }
                            }
                        }
                    }

                    return (result);

                } else {
                    if (f.query._original.charAt(0) == "<") {

                        var search = f.query._original.substring(1);

                        if (search.indexOf('-') != -1) {
                            var until_date = moment(f.query._original.substring(1), 'YYYY-MM-DD');
                        } else {
                            var until_date = moment(f.query._original.substring(1), 'DD.MM.YYYY');
                        }

                        // check all columns that have a date
                        var to_check_dates = f.columns.filter(function (c) {
                            return c.type == 'date'
                        })

                        if (to_check_dates.length != 0) {
                            result = false;

                            for (var i = 0; i < to_check_dates.length; i++) {
                                var to_check_date = moment(self.value[to_check_dates[i].name], 'DD.MM.YYYY');

                                if (until_date.isValid()) {
                                    result = to_check_date.isSameOrBefore(until_date);

                                    if (result) {
                                        return (result)
                                    }
                                }
                            }
                        }

                        return (result);

                    } else {
                        if ((result = f.matchRow(self)) == false) return false;
                    }
                }
            }
        });
        return result;
    };


    F.Snapshot = F.Component.extend({

        construct: function (table) {
            // call the constructor of the base class
            this._super(table, true);
            this.snapshot = [];
        },
        init: function () {},
        predraw: function () {
            this.snapshot = this.ft.rows.array.slice(0);
        },
        draw: function () {},
    });

    // register the component using a priority of 450 which falls between filtering (500) and paging (400).
    F.components.register("snapshot", F.Snapshot, 450);

    two_decimal_formatter = function (value) {
        return value.toFixed(2);
    };



})(jQuery, FooTable);